


<template>
  <div class="relative text-center">
    <div class="vx-navbar-wrapper-bottom bg-white px-5">
      <h2>Melayani Tanpa Lelet</h2>
      <h2 class="mb-2">#gakpakelama</h2>
    </div>
    <!--
    <div class="vx-navbar-wrapper-bottom" :class="{'hidden': navbarStatus}">
      <vs-navbar class="vx-navbar navbar-custom navbar-skelton" :class="textColor">

        <template v-if="'/' == $route.path">
          <div class="text-center" >
              <feather-icon icon="HomeIcon" class="text-warning" svgClasses="w-6 h-6"/>
              <p class="text-sm text-warning">Home</p>
          </div>
        </template>

        <template v-else>
          <router-link class="text-center text-white" to="/" href="/" tabindex="-1">
            <feather-icon icon="HomeIcon" svgClasses="w-6 h-6"/>
            <p class="text-sm">Home</p>
          </router-link>
        </template>

        <vs-spacer />

        <template v-if="'/jadwal' == $route.path">
          <div style="position: absolute;bottom: 30px;">
            <vs-button size="large" radius color="warning" type="filled" icon-pack="feather" icon="icon-calendar" @click="$router.push('/jadwal').catch(() => {})" svgClasses="w-16 h-16" text-color="rgb(30,30,30)"></vs-button>
          </div>
          <p class="text-sm pt-8 pl-1 text-warning">Jadwal</p>
        </template>

        <template v-else>
          <div style="position: absolute;bottom: 30px;">
            <vs-button size="large" radius color="warning" type="filled" icon-pack="feather" icon="icon-calendar" @click="$router.push('/jadwal').catch(() => {})" svgClasses="w-16 h-16" text-color="rgb(30,30,30)"></vs-button>
          </div>
          <p class="text-sm pt-8 pl-1">Jadwal</p>
        </template>

        <vs-spacer />

        <template v-if="'/permohonan/all/all' == $route.path">
          <div class="text-center" >
              <feather-icon icon="BriefcaseIcon" class="text-warning" svgClasses="w-6 h-6"/>
              <p class="text-sm text-warning">Berkas</p>
          </div>
        </template>

        <template v-else>
          <router-link class="text-center text-white" to="/permohonan/all/all" href="/permohonan/all/all" tabindex="-1">
            <feather-icon icon="BriefcaseIcon" svgClasses="w-6 h-6"/>
            <p class="text-sm">Berkas</p>
          </router-link>
        </template>

      </vs-navbar>
    </div>
    -->
  </div>
</template>


<script>
import Bookmarks            from "./components/Bookmarks.vue"
import SearchBar            from "./components/SearchBar.vue"
import NotificationDropDown from "./components/NotificationDropDown.vue"
import ProfileDropDown      from "./components/ProfileDropDown.vue"

export default {
  name: "the-navbar-vertical",
  props: {
    navbarColor: {
      type: String,
      default: "#fff",
    },
  },
  data () {
    return {
      logo_img: require('@/assets/images/logo/logo-phone.png')
    }
  }, 
  components: {
    Bookmarks,
    SearchBar,
    NotificationDropDown,
    ProfileDropDown,
  },
  computed: {
    verticalNavMenuWidth() {
      return this.$store.state.verticalNavMenuWidth
    },
    textColor() {
      return {'text-white': (this.navbarColor != '#10163a' && this.$store.state.theme === 'dark') || (this.navbarColor != '#fff' && this.$store.state.theme !== 'dark')}
    },
    windowWidth() {
      return this.$store.state.windowWidth
    },

    // NAVBAR STYLE
    classObj() {
      if (this.verticalNavMenuWidth == "default")      return "navbar-default"
      else if (this.verticalNavMenuWidth == "reduced") return "navbar-reduced"
      else if (this.verticalNavMenuWidth)              return "navbar-full"
    },

    navbarStatus () {
      return this.$store.state.navbarHidden
    },

    activeLink() {
      return ((this.to == this.$route.path) || (this.$route.meta.parent == this.slug) && this.to) ? true : false
    }
  },
  methods: {
    showSidebar() {
      this.$store.commit('TOGGLE_IS_VERTICAL_NAV_MENU_ACTIVE', true);
    }
  }
}
</script>




<template>
  <div class="layout--main" :class="[layoutTypeClass, navbarClasses, footerClasses, {'scroll': isAppPage}]">

    <!--
    <v-nav-menu
      :navMenuItems = "navMenuItems"
      parent        = ".layout--main" />
    -->

    <div id="content-area" :class="[contentAreaClass, {'show-overlay': bodyOverlay}]">
      <div id="content-overlay" />

      
      <the-navbar-vertical
        :navbarColor="navbarColor"
        :class="[
          {'text-white' : isNavbarDark  && !isThemeDark},
          {'text-base'  : !isNavbarDark && isThemeDark}
        ]" 
      />
      

      <div class="content-wrapper">

        <div class="router-view">
          <div class="router-content">

            <transition :name="routerTransition">

              <div v-if="$route.meta.breadcrumb || $route.meta.pageTitle" class="router-header flex flex-wrap items-center mb-6">
                <div
                  class="content-area__heading"
                  :class="{'pr-4 border-0 md:border-r border-solid border-grey-light' : $route.meta.breadcrumb}">
                  <h2 class="mb-1">{{ routeTitle }}</h2>
                </div>

                <!-- BREADCRUMB -->
                <vx-breadcrumb class="ml-4 md:block hidden" v-if="$route.meta.breadcrumb" :route="$route" :isRTL="$vs.rtl" />

                <!-- DROPDOWN 
                <vs-dropdown vs-trigger-click class="ml-auto md:block hidden cursor-pointer">
                  <vs-button radius icon="icon-settings" icon-pack="feather" />

                  <vs-dropdown-menu class="w-32">
                    <vs-dropdown-item>
                      <div @click="$router.push('/pages/profile').catch(() => {})" class="flex items-center">
                        <feather-icon icon="UserIcon" class="inline-block mr-2" svgClasses="w-4 h-4" />
                        <span>Profile</span>
                      </div>
                    </vs-dropdown-item>
                    <vs-dropdown-item>
                      <div @click="$router.push('/apps/todo').catch(() => {})" class="flex items-center">
                        <feather-icon icon="CheckSquareIcon" class="inline-block mr-2" svgClasses="w-4 h-4" />
                        <span>Tasks</span>
                      </div>
                    </vs-dropdown-item>
                    <vs-dropdown-item>
                      <div @click="$router.push('/apps/email').catch(() => {})" class="flex items-center">
                        <feather-icon icon="MailIcon" class="inline-block mr-2" svgClasses="w-4 h-4" />
                        <span>Inbox</span>
                      </div>
                    </vs-dropdown-item>
                  </vs-dropdown-menu>

                </vs-dropdown>
                -->

              </div>
            </transition>

            <div class="content-area__content">

              <back-to-top bottom="10%" :right="$vs.rtl ? 'calc(100% - 2.2rem - 38px)' : '15px'" visibleoffset="500" v-if="!hideScrollToTop">
                <vs-button icon-pack="feather" icon="icon-arrow-up" class="shadow-lg btn-back-to-top" />
              </back-to-top>

              <transition :name="routerTransition" mode="out-in">
                <router-view @changeRouteTitle="changeRouteTitle" @setAppClasses="(classesStr) => $emit('setAppClasses', classesStr)" />
              </transition>
            </div>
          </div>
        </div>
      </div>

      <!--
      <the-footer />
      -->
      
      <the-navbar-bottom-vertical
        :navbarColor="navbarColor"
        :class="[
          {'text-white' : isNavbarDark  && !isThemeDark},
          {'text-base'  : !isNavbarDark && isThemeDark}
        ]" 
      />
    

    </div>

  </div>
</template>


<script>
  import BackToTop           from 'vue-backtotop'
  import HNavMenu            from "@/layouts/components/horizontal-nav-menu/HorizontalNavMenu.vue"
  import navMenuItems        from "@/layouts/components/vertical-nav-menu/navMenuItems.js"
  import TheNavbarHorizontal from '@/layouts/components/navbar/TheNavbarHorizontal.vue'
  import TheNavbarVertical   from '@/layouts/components/navbar/TheNavbarVertical.vue'
  import TheNavbarBottomVertical   from '@/layouts/components/navbar/TheNavbarBottomVertical.vue'
  import TheFooter           from '@/layouts/components/TheFooter.vue'
  import themeConfig         from '@/../themeConfig.js'
  import VNavMenu            from '@/layouts/components/vertical-nav-menu/VerticalNavMenu.vue'
  import { mapGetters } from 'vuex'

  export default {
    components: {
      BackToTop,
      HNavMenu,
      TheFooter,
      TheNavbarHorizontal,
      TheNavbarVertical,
      TheNavbarBottomVertical,
      VNavMenu
    },
    data() {
      return {
        footerType        : themeConfig.footerType  || 'static',
        hideScrollToTop   : themeConfig.hideScrollToTop,
        isNavbarDark      : false,
        navbarColor       : themeConfig.navbarColor || '#fff',
        navbarType        : themeConfig.navbarType  || 'floating',
        navMenuItems      : navMenuItems,
        routerTransition  : themeConfig.routerTransition || 'none',
        routeTitle        : this.$route.meta.pageTitle,
        bclose            : true,
        colorx            : "#4a5153",
        alasanUpgrade     : null,
        linkUpgrade       : null,
        activeUpgrade     : true
      }
    },
    watch: {
      "$route"() {
        this.routeTitle = this.$route.meta.pageTitle
      },
      isThemeDark(val) {
        const color = this.navbarColor == "#fff" && val ? "#10163a" : "#fff"
        this.updateNavbarColor(color)
      },
      "$store.state.mainLayoutType"(val) {
        this.setNavMenuVisibility(val)
      }
    },
    computed: {
      bodyOverlay() { return this.$store.state.bodyOverlay },
      contentAreaClass() {
        if(this.mainLayoutType === "vertical") {
          if      (this.verticalNavMenuWidth == "default") return "content-area-reduced"
          else if (this.verticalNavMenuWidth == "reduced") return "content-area-lg"
          else return "content-area-full"
        }
        // else if(this.mainLayoutType === "boxed") return "content-area-reduced"
        else return "content-area-full"
      },
      footerClasses() {
        return {
          'footer-hidden': this.footerType == 'hidden',
          'footer-sticky': this.footerType == 'sticky',
          'footer-static': this.footerType == 'static',
        }
      },
      isAppPage() {
        return this.$route.meta.no_scroll
      },
      isThemeDark()     { return this.$store.state.theme == 'dark' },
      layoutTypeClass() { return `main-${this.mainLayoutType}` },
      mainLayoutType()  { return this.$store.state.mainLayoutType  },
      navbarClasses()   {
        return {
          'navbar-hidden'   : this.navbarType == 'hidden',
          'navbar-sticky'   : this.navbarType == 'sticky',
          'navbar-static'   : this.navbarType == 'static',
          'navbar-floating' : this.navbarType == 'floating',
        }
      },
      verticalNavMenuWidth() { return this.$store.state.verticalNavMenuWidth },
      windowWidth() { return this.$store.state.windowWidth },
      ...mapGetters({ user : 'auth/user' })
    },
    methods: {
      changeRouteTitle(title) {
        this.routeTitle = title
      },
      updateNavbarColor(val) {
        this.navbarColor = val
        if (val == "#fff") this.isNavbarDark = false
        else this.isNavbarDark = true
      },
      setNavMenuVisibility(layoutType) {
        if((layoutType === 'horizontal' && this.windowWidth >= 1200) || (layoutType === "vertical" && this.windowWidth < 1200)) {
          this.$store.commit('TOGGLE_IS_VERTICAL_NAV_MENU_ACTIVE', false)
          this.$store.dispatch('updateVerticalNavMenuWidth', 'no-nav-menu')
        }
        else {
          this.$store.commit('TOGGLE_IS_VERTICAL_NAV_MENU_ACTIVE', true)
        }
      },
      onBackButton() {
        this.$router.push("/")
      }, 
      alertUpdate(){
        this.$vs.dialog({
          type:'confirm',
          color: 'success',
          title: `Upgrade Aplikasi`,
          text: 'Anda diharuskan meng-upgrade aplikasi dikarenakan ada perbaikan dan update fitur',
          accept: this.toUpgrade,
          //buttonCancel:'hide',
          //cancel: this.alertUpdate2
        })
      }, 
      toUpgrade () {
        window.open(this.linkUpgrade, "_blank");
        this.alertUpdate()
      }
    },
    created() {
      const color = this.navbarColor == "#fff" && this.isThemeDark ? "#10163a" : this.navbarColor
      this.updateNavbarColor(color)
      this.setNavMenuVisibility(this.$store.state.mainLayoutType)
      
      //if (this.user.api_token) {
        //const obj = { filter: 'all', type : 'berkas' }
        //this.$store.dispatch('taskUser', obj)
        //const objJadwal = { filter: 'all', type : 'jadwal', //get_parent: 'true' }
        //this.$store.dispatch('jadwalUser', objJadwal)
      //}
      if (themeConfig.android) {
        this.axios.get('/options-select-name/android_version')
        .then((response) => {
          if (themeConfig.androidVersion != response.data.data.option_value) {
            this.alertUpdate()
          }
        })
        this.axios.get('/options-select-name/android_link_upgrade')
        .then((response) => {
          this.linkUpgrade = response.data.data.option_value
        })
      } else if (themeConfig.iOS) {
        this.axios.get('/options-select-name/ios_version')
        .then((response) => {
          if (themeConfig.androidVersion != response.data.data.option_value) {
            this.alertUpdate()
          }
        })
      }

      this.$store.dispatch('layananGroup')

      document.addEventListener('backbutton', this.onBackButton, true);
    }
  }
</script>
<style lang="scss">
.vs-dialog-cancel-button {
  display: none;
}
</style>

